import { Grid, List, ListItem, Typography } from "@mui/material";
import { Align } from "ui/Align";
import { Link } from "ui/Link";
import React from "react";

export function Library() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Align center>
                    <Typography
                        component="h4"
                        variant="h4"
                        gutterBottom
                        align="center"
                    >
                        Library
                    </Typography>
                </Align>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    We publish several tools and libraries written as part of
                    the QPED project on{" "}
                    <a href="https://github.com/qped-eu" target="_blank">
                        GitHub
                    </a>
                    . The following libraries are available:
                </Typography>
                <ul>
                    <li>
                        <Typography>
                            <a
                                href="https://github.com/qped-eu/MASS-checker"
                                target="_blank"
                            >
                                MASS checker
                            </a>
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <a
                                href="https://github.com/qped-eu/mass"
                                target="_blank"
                            >
                                MASS configuration generator
                            </a>
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <a
                                href="https://github.com/qped-eu/rubrics-creation-tool"
                                target="_blank"
                            >
                                Rubrics creation tool
                            </a>
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <a
                                href="https://github.com/qped-eu/PythonChecker"
                                target="_blank"
                            >
                                Python style checker tool
                            </a>
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <a
                                href="https://github.com/quarterfall/cloudcheck"
                                target="_blank"
                            >
                                Cloud-based code running server
                            </a>
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <a
                                href="https://github.com/quarterfall/parseidon"
                                target="_blank"
                            >
                                ParseidonJS: analyze design patterns in Mermaid
                                diagrams
                            </a>
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <a href="./pg.pdf" target="_blank">
                                Procedural guidance
                            </a>
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <a href="./broeders2022.pdf" target="_blank">
                                Master thesis: "HOW DO EXPLICIT SPECIFICATIONS,
                                A TEST-FIRST APPROACH, AND PROCEDURAL GUIDANCE
                                TO CONVERT SPECIFICATIONS TO TESTS, HELP TO
                                IMPROVE CODE QUALITY AMONG STUDENTS" by Arno
                                Broeders.
                            </a>
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <a
                                href="https://tile-repository.github.io"
                                target="_blank"
                            >
                                TILE repository
                            </a>
                        </Typography>
                    </li>
                </ul>
            </Grid>
        </Grid>
    );
}
