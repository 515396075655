import { Box, BoxProps } from "@mui/system";
import React from "react";

export interface AlignProps extends BoxProps {
    right?: boolean;
    center?: boolean;
    left?: boolean;
}

export function Align(props: AlignProps) {
    const { className, right, center, left, ...rest } = props;

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: left
                    ? "flex-start"
                    : right
                    ? "flex-end"
                    : "center",
                width: "100%",
            }}
            {...rest}
        />
    );
}
