import { Grid, Typography } from "@mui/material";
import React from "react";
import { Align } from "ui/Align";

export function Contact() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Align center>
                    <Typography
                        component="h4"
                        variant="h4"
                        gutterBottom
                        align="center"
                    >
                        Contact
                    </Typography>
                </Align>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    For more information about the project, please contact the{" "}
                    <a href="mailto:harrie.passier@ou.nl">coordinator</a> of the
                    project.
                </Typography>
            </Grid>
        </Grid>
    );
}
