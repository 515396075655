import {
    Button,
    Container,
    CssBaseline,
    Divider,
    Grid,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { Contact } from "Contact";
import { HomeImage } from "HomeImage";
import { Library } from "Library";
import { Partners } from "Partners";
import { Helmet } from "react-helmet";
import { theme } from "theme";
import { Align } from "ui/Align";
import { Link } from "ui/Link";
import { Footer } from "../Footer";
import { Header } from "../Header";
import React from "react";

export default function Index() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Helmet
                htmlAttributes={{
                    lang: "en",
                }}
            >
                <meta charSet="utf-8" />
                <title>QPED: Quality-focussed Programming Education</title>
                <meta
                    name="description"
                    content="QPED: Quality-focussed Programming Education"
                />
                <link rel="canonical" href="https://www.qped.eu" />
            </Helmet>
            <Header />
            <HomeImage
                title="QPED"
                caption="Quality-focussed Programming Education"
            />
            {/* Hero unit */}
            <Container
                id="about"
                maxWidth="md"
                component="main"
                sx={{
                    paddingTop: 8,
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingBottom: 6,
                }}
            >
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                >
                    Improving education in programming
                </Typography>
                <Typography align="center" color="textSecondary" gutterBottom>
                    Our society heavily depends on software in almost every area
                    of life, ranging from social-media apps installed on
                    millions of mobile devices up to life-critical software in
                    the automotive or avionic sector. Because we are so
                    dependent on software, it is important that it functions
                    correctly, can be maintained and, because it is used in many
                    different contexts and operated by humans, is robust.
                    Unfortunately, this is not the reality. Although tools and
                    techniques exist to improve software quality, today's
                    programmers seem reluctant to use them.
                </Typography>
                <Typography align="center" color="textSecondary">
                    To remedy this we need to redesign the curriculum for
                    software engineering and general computer science studies,
                    and put more focus on quality assurance and software testing
                    early on. The Erasmus+ Project QPED (Quality-focused
                    Programming Education) will create new course material and a
                    toolkit to achieve this.
                </Typography>
                <Typography align="center" color="textSecondary" gutterBottom>
                    The new course material will put emphasis on proper testing
                    as early in the education as possible, to foster a
                    quality-awareness in students that goes beyond functional
                    correctness as demonstrated by testing; they will also start
                    thinking about better readable and more robust code.
                </Typography>
                <Typography align="center" color="textSecondary" gutterBottom>
                    To ensure that testing and paying attention to code quality
                    is not only taught early, but also performed by students
                    from the beginning, we will provide a toolkit that can
                    automatically provide feedback on the quality of the code
                    produced by students. It will support the most common
                    object-oriented and imperative programming languages, such
                    as Java, Python and C.
                </Typography>
                <Typography align="center" color="textSecondary" gutterBottom>
                    The toolkit and all material will be provided free of charge
                    and can be used inside the Quarterfall learning platform.
                </Typography>
                <Align center>
                    <Button
                        size="large"
                        variant="contained"
                        href="ios"
                        color="secondary"
                        style={{ marginTop: 32 }}
                    >
                        Read more
                    </Button>
                </Align>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md">
                <Grid container direction="column" spacing={4}>
                    <Grid item id="library">
                        <Library />
                    </Grid>
                    <Grid item id="partners">
                        <Partners />
                    </Grid>
                    <Grid item id="contact">
                        <Contact />
                    </Grid>
                    <Grid item>
                        <Divider />
                        <Align center>
                            <Link
                                href="https://ec.europa.eu/programmes/erasmus-plus/node_en"
                                target="_blank"
                            >
                                <img
                                    src={`/erasmus.png`}
                                    alt={"Erasmus"}
                                    style={{ width: 400, marginTop: 32 }}
                                />
                            </Link>
                        </Align>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </ThemeProvider>
    );
}
