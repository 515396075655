import { Grid, Typography } from "@mui/material";
import React from "react";
import { Align } from "ui/Align";
import { Link } from "ui/Link";

export function Partners() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Align center>
                    <Typography
                        component="h4"
                        variant="h4"
                        gutterBottom
                        align="center"
                    >
                        Partners
                    </Typography>
                </Align>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Link href="https://www.ou.nl" target="_blank">
                    <img src="partners/ou.png" style={{ width: 200 }} />
                </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Link href="https://www.uni-marburg.de" target="_blank">
                    <img src="partners/marburg.svg" style={{ width: 200 }} />
                </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Link href="https://www.tue.nl" target="_blank">
                    <img src="partners/tue.png" style={{ width: 200 }} />
                </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Link href="https://www.uoc.edu/" target="_blank">
                    <img src="partners/uoc.png" style={{ width: 200 }} />
                </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Link href="https://www.quarterfall.com" target="_blank">
                    <img
                        src="partners/quarterfall.png"
                        style={{ width: 200 }}
                    />
                </Link>
            </Grid>
        </Grid>
    );
}
